/*
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in providing paths for API Route URL for every modules.
      Developer: Aashish Singh, Created Date: 29-March-2023
</summary>
*/

const URLConstants: any = {
  Authenticate: "/Account/Login",
  Logout: "/Account/Logout",
  ActivateAccount: "/Account/Activate",
  ResendLink: "Account/Activate/Resend/Link",
  AddKmsUser: "/User",
  GetKmsUserList: "/User/List",
  GetSystemUserList: "/User/TenantId",
  GetUserById: "/User",
  DeleteUser: "/User",
  UpdateUser: "/User",
  TenantList: "/Tenant",
  GetTenantById: "/Tenant",
  AddTenant: "/Tenant",
  UpdateTenant: "/Tenant",
  DeleteTenant: "/Tenant",
  AllTenants: "/Tenant",
  Branch: "/BranchManagement",
  AddUser: "/v1/User/AddUser",
  AllBranches: "/BranchManagement/All",
  UpdateUserPassword: "/Account/Update/Tenant/ChangePassword",
  VerifyUserEmail: "/User/UserEmail",
  ResetPassword: "/Account/Update/User/Password ",

  GetParkingLotById: "/ParkingLots",
  ParkingLotList: "/ParkingLots/List",
  AddParkingLot: "/ParkingLots",
  ParkingLot: "/ParkingLots",
  DeleteParkingLot: "ParkingLots",
  AllParkingLot: "ParkingLots/All",
  UpdateParkingLot: "ParkingLots/CurrentStatus",
  SetParkingLotThreshold: "ParkingLots/Threshold",
  GetParkingLotFilterList: "ParkingLots/Filter",
  GetParkingLotStatus: "ParkingLots/ParkingLotStatus",

  AddMediaFolder: "/MediaManagement/Folder/Add",
  UpdateMediaFolder: "/MediaManagement/Folder/Update",
  DeleteMediaFolder: "/MediaManagement/Folder/Delete",
  GetMediaFolderList: "/MediaManagement/Folder/List",
  GetAllMediaFolders: "/MediaManagement/Folder/All",
  GetMediaFolderById: "/MediaManagement/Folder/Id",
  GetFolderStyleById: "MediaManagement/Folder/MediaCategory/Type",

  AddCamera: "/Camera",
  UpdateCamera: "/Camera",
  DeleteCamera: "/Camera",
  GetCameraList: "/Camera/List",
  GetCameraById: "/Camera",
  GetAllCamera: "/Camera/All",
  GetCameraSlotCountById: "Camera/PlId/Slots/Count",
  GetCameraSlotById: "Camera/Slots/CameraId",

  GetMediaList: "/MediaManagement/Media/List",

  AddImage: "/MediaManagement/Folder/Images/Add",
  UpdateImage: "/MediaManagement/Folder/Images/Update",
  UploadImage: "/MediaManagement/Folder/Images/Upload",
  GetAllImagesFolderId: "/MediaManagement/Folder/Images/FolderId",
  GetAllImages: "/MediaManagement/Folder/Images/All",
  GetImageById: "/MediaManagement/Folder/Images/Id",
  DeleteImage: "/MediaManagement/Folder/Images/Delete",

  AddVideo: "/MediaManagement/Folder/Videos/Add",
  UpdateVideo: "/MediaManagement/Folder/Videos/Update",
  UploadVideo: "/MediaManagement/Folder/Videos/Upload",
  GetVideoById: "/MediaManagement/Folder/Videos/Id",
  DeleteVideo: "/MediaManagement/Folder/Videos/Delete",

  AddPriceScheduler: "PriceScheduler",
  UpdatePriceScheduler: "PriceScheduler",
  GetPriceSchedulerList: "PriceScheduler/List",
  GetPriceSchedulerById: "PriceScheduler",
  GetAllPriceScheduler: "PriceScheduler/All",
  DeletePriceScheduler: "PriceScheduler",
  GetPriceSchedulerMap: "PriceScheduler/ImageDigitMapping/All",
  SetPriceSchedulerMap: "PriceScheduler/ImageMapping",
  UpdatePriceSchedulerMap: "PriceScheduler/ImageDigitMapping",
  GetCurrentSchedule: "PriceScheduler/CurrentSchedule/Id",
  SignBoardSchedule: "PriceScheduler/SignBoardImage/PlId",
  AddSignBoardSchedule: "PriceScheduler/SignBoardImage",
  UpdateSignBoardSchedule: "PriceScheduler/SignBoardImage",

  GetSpecialSchedulerList: "PriceScheduler/SpecialSchedule/List",
  AddSpecialScheduler: "PriceScheduler/SpecialSchedule",
  UpdateSpecialScheduler: "PriceScheduler/SpecialSchedule",
  DeleteSpecialScheduler: "PriceScheduler/SpecialSchedule",
  GetSpecialSchedulerById: "PriceScheduler/SpecialSchedule/Id",
  GetAllSpecialScheduler: "PriceScheduler/SpecialSchedule/All",
  AddScheduleCSV: "PriceScheduler/CSV",

  SetParkingLotStatusImage: "ParkingLots/ImageStatus",
  UpdateParkingLotStatusImage: "ParkingLots/ImageStatus",
  GetParkingLotStatusImageById: "ParkingLots/ImageStatusDetail/All",
  AllCategoryParkingLot: "ParkingLots/Category/All",

  UserLogsList: "Logs/User/List",
  UserLogsFilterList: "Logs/Filter",
  SystemLogsFilterList: "Logs/Filter/SystemLog",
  SystemLogsList: "Logs/System/List",
  Version: "/v1/Version",

  GetAllLinkbaseInRelay: "LinkBase/AllLinkBaseInRelay",
  GetLinkBaseInRelayByPlId: "LinkBase/AllLinkBaseIn/PlId",
  AddLinkBaseIn: "LinkBase/LinkBaseIn",
  UpdateLinkBaseIn: "LinkBase/LinkBaseIn",
  GetLinkBaseInByRelayType: "LinkBase/AllLinkBaseInRelay/Type",

  GetProductsList: "Product/List",
  UpdateProduct: "Product",
  GetProductById: "Product",
  DeleteProduct: "Product",
  AddProduct: "Product",
  GetWeightedProductsList: "Product/Weight",
  GetAllProduct: "Product/AllProduct",
  UpdateWeightedProductSku: "Product/UpdateSku",
  GetWeightedProductById: "Product/Weight",
  ExportFile: "Product/ExportWeight",
};

export default URLConstants;
