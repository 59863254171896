/* eslint-disable jsx-a11y/anchor-is-valid */
import { enIN } from "date-fns/locale";
import { Field, Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import FormikFormDatePicker from "../../../shared-components/FormikFormHandlers/FormikFormDatePicker";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { filterValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import Label from "../../../shared-components/label/label";

registerLocale("en-IN", enIN);

interface IProps {
  initialValues: any;
  id: number;
  submitHandler: (data: any) => void;
  filterParmas: (data: any, isfilterCleard: boolean) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  isDisable: boolean;
  isFilterCleared: boolean;
}
const FilterComponentNew = (props: IProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggle = (isOpen: boolean) => setShowDropdown(isOpen);

  function getLocalISODate(isoDate: Date) {
    return (
      isoDate.getFullYear() +
      "-" +
      String(isoDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(isoDate.getDate()).padStart(2, "0") +
      "T" +
      String(isoDate.getHours()).padStart(2, "0") +
      ":" +
      String(isoDate.getMinutes()).padStart(2, "0") +
      ":" +
      String(isoDate.getSeconds()).padStart(2, "0")
    );
  }

  function transformQuery(input: string): string {
    const params = new URLSearchParams(input);
    const startDate = params.get("startDate")
      ? new Date(params.get("startDate") || "")
      : null;
    const endDate = params.get("endDate")
      ? new Date(params.get("endDate") || "")
      : null;
    const name = params.get("name") || "";
    const skuList = (params.get("sku") || "")
      .split(",")
      .filter((sku) => sku.trim() !== "");

    // Helper to format date to 'YYYY-MM-DD HH:mm:ss' for SQL-like query
    // const formatDate = (date: Date) =>
    //   getLocalISODate(date).slice(0, 19).replace("T", " ");

    const formatDate = (date: Date) => getLocalISODate(date).slice(0, 10);

    let queryParts: string[] = [];

    // Add name filter if 'name' is not empty
    if (name) {
      queryParts.push(`name ILIKE '%${name}%'`);
    }

    // Add SKU filter if skuList is not empty
    if (skuList.length > 0) {
      queryParts.push(
        `LOWER(D.sku) IN (${skuList
          .map((sku) => `'${sku.trim().toLowerCase()}'`)
          .join(",")})`
      );
    }

    // Add date filters if startDate and endDate are valid
    if (startDate) {
      queryParts.push(`DATE(added_date)  >= '${formatDate(startDate)}'`);
    }
    if (endDate) {
      queryParts.push(`DATE(added_date)  <= '${formatDate(endDate)}'`);
    }

    // Combine all parts with "AND" and return as a single string
    return `${queryParts.join(" AND ")}`;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={props.initialValues}
        enableReinitialize
        validationSchema={filterValidationSchema}
        validateOnBlur={false}
        onSubmit={async (
          { StartDate, EndDate, Name, Sku },
          { resetForm, setFieldValue }
        ) => {
          // Initialize query string parts
          props.filterParmas(
            {
              ProductName: Name || "",
              Sku:
                Sku.length > 0
                  ? Sku.split(",")
                      .map((item: string) => `'${item}'`)
                      .join(",")
                  : "",
              ColumnOrder: "",
              StartDate: StartDate ? getLocalISODate(new Date(StartDate)) : "",
              EndDate: EndDate ? getLocalISODate(new Date(EndDate)) : "",
            },
            false
          );
          const queryParams: string[] = [];

          if (StartDate) queryParams.push(`startDate=${new Date(StartDate)}`);
          if (EndDate) queryParams.push(`endDate=${new Date(EndDate)}`);
          if (Name) queryParams.push(`name=${Name}`);
          if (Sku) queryParams.push(`sku=${Sku}`);
          const queryString = queryParams.join("&");
          const filter = transformQuery(queryString);
          props.submitHandler(filter);
          setShowDropdown(false);
        }}
      >
        {({ dirty, resetForm, setFieldValue }) => {
          return (
            <Dropdown
              className={`Filter-Kitchen ${
                props.isDisable ? "disabled-li" : ""
              }`}
              show={showDropdown}
              onToggle={handleToggle}
            >
              <Dropdown.Toggle
                className={`btn btn-light `}
                aria-expanded="false"
              >
                <svg
                  className={`svg-icon ${props.isDisable ? "disabled-li" : ""}`}
                  width="20px"
                  height="20px"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                    fill="currentColor"
                  ></path>
                </svg>
              </Dropdown.Toggle>
              <FormikForm className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Dropdown.Menu className="filter-menu">
                  <Dropdown.ItemText className="p-0">
                    <Form.Group controlId="dateRange">
                      <div
                        className={`filter-header border-bottom p-2 pb-1 ${
                          props.isFilterCleared ? "d-none" : ""
                        }`}
                      >
                        <h4>
                          Filter by Date Range
                          <a
                            className="clear-filter float-end"
                            onClick={() => {
                              props.filterParmas(
                                {
                                  ProductName: "",
                                  Sku: "",
                                  StartDate: "",
                                  EndDate: "",
                                },
                                true
                              );
                              setFieldValue("StartDate", null);
                              setFieldValue("EndDate", null);
                              resetForm();
                              setShowDropdown(false);
                            }}
                          >
                            Clear Filters
                          </a>
                        </h4>
                      </div>
                      <Row className="p-3">
                        <Col md={6}>
                          <Label
                            className="label-style  col-form-label"
                            required={false}
                            label="start_date"
                            htmlFor="StartDate"
                          />
                          <Field
                            data-testid="Name"
                            className="form-control"
                            name="StartDate"
                            autoComplete="off"
                            placeholder=""
                            as={FormikFormDatePicker}
                            maxDate={new Date()}
                          />
                        </Col>
                        <Col md={6}>
                          <Label
                            className="label-style  col-form-label"
                            required={false}
                            label="end_date"
                            htmlFor="EndDate"
                          />
                          <Field
                            data-testid="Name"
                            className="form-control"
                            name="EndDate"
                            autoComplete="off"
                            placeholder=""
                            as={FormikFormDatePicker}
                            maxDate={new Date()}
                          />
                        </Col>

                        <Col md={6}>
                          <Form.Group controlId="nameFilter">
                            <Label
                              className="label-style  col-form-label"
                              required={false}
                              label="name"
                            />
                            <Field
                              data-testid="Name"
                              className="form-control"
                              name="Name"
                              autoComplete="off"
                              type="text"
                              placeholder=""
                              as={FormikFormInput}
                            />
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group controlId="skuFilter">
                            <Label
                              className="label-style  col-form-label"
                              required={false}
                              label="Sku"
                            />
                            <Field
                              data-testid="Sku"
                              className="form-control"
                              name="Sku"
                              autoComplete="off"
                              type="text"
                              placeholder=""
                              as={FormikFormInput}
                              validcharacter={[
                                "?",
                                ">",
                                "<",
                                "'",
                                '"',
                                ":",
                                ";",
                                ",",
                                "+",
                                "-",
                                "(",
                                ")",
                                "*",
                                "&",
                                "^",
                                "%",
                                "$",
                                "#",
                                "{",
                                "[",
                                "]",
                                "}",
                                "|",
                                "/",
                                "\\",
                                "=",
                                "_",
                                "!",
                                "~",
                                "`",
                                ".",
                                "@",
                              ]}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  <div className="text-end me-3">
                    <Button variant="primary" type="submit" className="">
                      Apply Filters
                    </Button>
                    {/* <Button
                      variant="secondary"
                      className="mt-3"
                      onClick={() => {
                        props.filterParmas(
                          {
                            ProductName: "",
                            Sku: "",
                            StartDate: "",
                            EndDate: "",
                          },
                          true
                        );
                        setFieldValue("StartDate", null);
                        setFieldValue("EndDate", null);
                        resetForm();
                      }}
                    >
                      Clear Filters
                    </Button> */}
                  </div>
                </Dropdown.Menu>
              </FormikForm>
            </Dropdown>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default FilterComponentNew;
